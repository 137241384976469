import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

export default () => {
    Swiper.use([Autoplay, Navigation, Pagination]);

    const bulletTailwind = [
        'relative',
        'group',
        'block',
        'w-5',
        'h-5',
        'rounded-full',
        'bg-brand-color-1',
        'border-transparent',
        'opacity-20',
        'cursor-pointer',
        'duration-500',
        'hover:opacity-50',
        'hover:scale-75',
    ];
    const bulletActiveTailwind = [
        'cursor-auto',
        '!opacity-100',
        '!scale-100',
        '!border-brand-color-1',
    ];

    new Swiper('.js-o-slider-b-1', {
        loop: false,
        speed: 700,
        watchOverflow: true,
        noSwipingSelector: 'a',
        slidesPerView: 1,
        spaceBetween: 20,
        breakpoints: {
            767: {
                spaceBetween: 50,
            },
        },
        navigation: {
            prevEl: '.js-o-slider-b-1-btn-prev',
            nextEl: '.js-o-slider-b-1-btn-next',
        },
        pagination: {
            el: '.js-o-slider-b-1-pagination',
            type: 'bullets',
            clickable: true,
            bulletClass: bulletTailwind.join(' '),
            bulletActiveClass: bulletActiveTailwind.join(' '),
            renderBullet: function (index, className) {
                let innerSpanClass = [
                    'absolute',
                    '-top-1.5',
                    '-bottom-1.5',
                    '-left-1.5',
                    '-right-1.5',
                    'border',
                    'border-solid',
                    'border-inherit',
                    'rounded-full',
                    'duration-500',
                    'group-hover:border-brand-color-1',
                ].join(' ');
                return `<span class="${className}"><span class="${innerSpanClass}"></span></span>`;
            },
        },
        on: {
            slideChange: function (sw) {
                const slider = sw.$el[0];
                const beginningClasses = 'sm:!pr-40 md:!pr-80';
                const endClasses = 'sm:!pl-40 md:!pl-80';
                const middleClasses = 'sm:!pl-20 sm:!pr-20 md:!pl-40 md:!pr-40';

                if (sw.isBeginning) {
                    $(slider).removeClass(middleClasses + ' ' + endClasses);
                    $(slider).addClass(beginningClasses);
                } else if (sw.isEnd) {
                    $(slider).removeClass(
                        middleClasses + ' ' + beginningClasses,
                    );
                    $(slider).addClass(endClasses);
                } else {
                    $(slider).removeClass(endClasses + ' ' + beginningClasses);
                    $(slider).addClass(middleClasses);
                }
            },
        },
    });
};
