import FormSection from '../formSection';

/**
 * This class is responsible for implementing the functionality of form section A-2
 *
 * @class FormSectionA2
 * @extends { FormSection }
 */
export default class FormSectionA2 extends FormSection {
    static TAX_RATE = 0.0325;
    static PRICE_LOCALE = 'en-US';
    static PRICE_FORMAT = {
        style: 'currency',
        currency: 'USD',
        useGrouping: true,
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        groupingSeparator: ' ',
        decimalSeparator: '.',
    };

    /**
     * Container of the current section
     * @param { HTMLElement } container
     */
    constructor(container) {
        super(container);
    }

    /**
     * Initializing some properties by extending the parent class method
     */
    initProperties() {
        super.initProperties();

        /**
         * HTMLCollection of all the form sections tables within the summary block
         * @type { null | HTMLCollection }
         */
        this.tables = this.container.querySelectorAll(
            `[data-role='summary-section-table']`,
        );
        this.pricingTables = this.container.querySelectorAll(
            `[data-role='summary-pricing-table']`,
        );
        this.options = FormSection.backendStaticData.options
            ? FormSection.backendStaticData.options
            : {};
    }

    /**
     * Attach Event Listeners
     */
    initListeners() {
        window.addEventListener(
            'sectionDataUpdated',
            this.sectionStateUpdatedHandler.bind(this),
        );
        this.container.addEventListener(
            'click',
            this.globalClicksHandler.bind(this),
        );
    }

    /**
     * Updates the currentStateData object on page loading.
     * @param { Object } sectionStateObject
     * @param { Object } formStateObject
     */
    setInitialState(sectionStateObject = {}, formStateObject = {}) {
        this.firstLoad = true;

        this.updateSummaryTables(formStateObject);
        this.isSectionInitialStateSet = true;
    }

    /**
     * Update all the summary blocks
     * @param { Object } formStateObject
     */
    updateSummaryTables(formStateObject) {
        for (const sectionId in formStateObject) {
            const table = this.getTableBySectionId(sectionId);
            if (table) {
                this.updateSummaryTable(table, formStateObject[sectionId]);
            }
        }
    }

    /**
     * Event handler for when the section state is updated.
     * @param { CustomEvent } event
     */
    sectionStateUpdatedHandler(event) {
        if (event.detail && event.detail.sectionStateData) {
            const table = this.getTableBySectionId(event.detail.section);
            if (table) {
                this.updateSummaryTable(table, event.detail.sectionStateData);
            }

            this.pricingTables.forEach((table) => {
                this.updatePricingTable(
                    table,
                    event.detail.sectionStateData,
                    event.detail.section,
                );
            });
        }
    }

    /**
     * Updates the passed summary table according to the current state
     *
     * @param table
     * @param sectionStateData
     * @param sectionId
     */
    updatePricingTable(table, sectionStateData, sectionId) {
        // Total upgrades markup value
        let totalUpgrades = 0;
        // Upgrades Rows
        const rows = table.querySelectorAll(`tr[data-role='upgrades-row']`);
        // Base Price Cell
        const basePriceCell = table.querySelector(
            `td[data-role='base-price-row-price']`,
        );
        const basePrice = parseFloat(basePriceCell.dataset.basePrice);
        // Tax Price Cell
        const taxPriceCell = table.querySelector(
            `td[data-role='tax-row-price']`,
        );
        // Total Net Price Cell
        const totalNetCell = table.querySelector(
            `td[data-role='total-row-price']`,
        );

        // Looping through all the upgrades options
        rows.forEach((row) => {
            // Current option ID
            const currentOptionId = row.dataset.optionId;
            // Current row related input name
            const inputName = row.dataset.inputId;
            // Value for this input from the current state
            const inputValue = sectionStateData[inputName];
            // Section that includes this question
            const currentOptionSection = row.dataset.section;

            // If current row is associated with another section no need to update its visibility status
            // However, we should consider its markup value if it is visible
            if (currentOptionSection !== sectionId) {
                if (row.dataset.visible === '1') {
                    totalUpgrades += parseFloat(row.dataset.markup);
                }
                return;
            }

            // If it is the same section and no value presents hide this row
            if (!inputValue) {
                row.dataset.visible = '0';
                return;
            }

            // Chosen Options (works for multiple values separated with a comma)
            const optionIds = inputValue.split(',');

            // If value for this row question does not include this row option hide it
            if (!optionIds.includes(currentOptionId)) {
                row.dataset.visible = '0';
                return;
            }

            // Otherwise, make this row visible
            row.dataset.visible = '1';

            // Chosen Options
            const chosenOption = this.getOptionById(parseInt(currentOptionId));
            if (!chosenOption) {
                return;
            }

            // Calculating total markup for all the upgrades
            const optionMarkup = chosenOption.markup
                ? parseFloat(chosenOption.markup)
                : 0;
            totalUpgrades += optionMarkup;
        });

        // Calculating new total price
        // New Base price + upgrades
        const newTotalPrice = basePrice + totalUpgrades;
        // New Tax value
        const newTaxPrice = newTotalPrice * FormSectionA2.TAX_RATE;
        // New Total value
        const newTotalNetPrice = newTotalPrice + newTaxPrice;

        // Updating some summary rows values
        if (taxPriceCell) {
            // Tax value
            taxPriceCell.innerText = `+ ${newTaxPrice.toLocaleString(
                FormSectionA2.PRICE_LOCALE,
                FormSectionA2.PRICE_FORMAT,
            )}`;
        }
        if (totalNetCell) {
            // Total Net value
            totalNetCell.innerText = `${newTotalNetPrice.toLocaleString(
                FormSectionA2.PRICE_LOCALE,
                FormSectionA2.PRICE_FORMAT,
            )}`;
        }

        const customEvent = new CustomEvent('totalNetPriceUpdated', {
            detail: {
                price: newTotalNetPrice,
            },
        });
        window.dispatchEvent(customEvent);
    }

    /**
     * Updates the summary table with the provided section state data.
     * @param { HTMLElement } table       - The table to update
     * @param { Object } sectionStateData - An object containing the input name/value pairs for the section
     */
    updateSummaryTable(table, sectionStateData) {
        const rows = table.querySelectorAll(`tr[data-input-id]`);
        rows.forEach((row) => {
            // Current row related input name
            const inputName = row.dataset.inputId;
            // Value for this input from the current state
            const inputValue = sectionStateData[inputName];

            // Set row's visibility according to the input's value : hide if no value presents
            if (!inputValue) {
                row.dataset.visible = '0';
                return;
            }
            row.dataset.visible = '1';

            // Chosen Options (works for multiple values separated with a comma)
            const optionIds = inputValue.split(',');
            // Chosen Options
            const options = optionIds.map((optionId) => {
                return this.getOptionById(parseInt(optionId));
            });
            if (!options.length) {
                return;
            }

            // Label tag
            const inputLabel = row.getAttribute('data-input-label')
                ? row.getAttribute('data-input-label')
                : '';
            // Value tag
            const cellValue = row.querySelector(`[data-role='cell-value']`);
            // Markup tag
            const cellMarkup = row.querySelector(`[data-role='cell-markup']`);

            // Label for the chosen option(s)
            const optionLabel = options
                .map((option) => {
                    return option.summary_block_label ?? option.post_title;
                })
                .join(', ');
            // Total markup for the chosen option(s)
            const optionMarkup = options.reduce((accumulator, option) => {
                return (
                    accumulator +
                    (option.markup ? parseFloat(option.markup) : 0)
                );
            }, 0);

            if (cellValue) {
                cellValue.innerText = `${inputLabel} - ${optionLabel}`;
            }
            if (cellMarkup) {
                if (optionMarkup > 0) {
                    cellMarkup.innerText = `${optionMarkup.toLocaleString(
                        FormSectionA2.PRICE_LOCALE,
                        FormSectionA2.PRICE_FORMAT,
                    )}`;
                } else {
                    cellMarkup.innerText = 'Incl';
                }
            }
        });
    }

    /**
     * Retrieves the option object for a given inputId and inputValue.
     * @param { number } optionId    - The ID of the input element
     * @returns { Object }          - The option object
     */
    getOptionById(optionId) {
        if (!optionId) {
            return {};
        }

        let option = {};
        if (this.options) {
            for (const index in this.options) {
                if (this.options[index].ID === optionId) {
                    return this.options[index];
                }
            }
            // const questionOptions = this.options[inputId];
            // for (const questionOption of questionOptions) {
            //     if (questionOption.value === inputValue) {
            //         option = questionOption;
            //         break;
            //     }
            // }
        }

        return option;
    }

    /**
     * Retrieves the table element for a given section ID.
     * @param { string } sectionId   - The ID of the section
     * @returns { HTMLElement|null } - The table element, or null if not found
     */
    getTableBySectionId(sectionId = '') {
        if (!sectionId) {
            return null;
        }

        const table = [...this.tables].find(
            (table) => table.getAttribute('data-section-id') === sectionId,
        );

        return table || null;
    }

    updateModel() {
        throw new Error('Form Section A 2 cannot include 3D model element');
    }

    globalClicksHandler(event) {
        const target = event.target;

        if (target.closest(`[data-role='summary-row-info']`)) {
            this.optionDetailsHandler(event);
        }
    }

    optionDetailsHandler(event) {
        event.preventDefault();

        const button = event.target.closest(`[data-role='summary-row-info']`);
        const row = button.closest(`tr[data-input-id]`);
        const table = button.closest(`table[data-section-id]`);
        if (!row || !table) {
            return;
        }

        const questionId = Number(row.dataset.inputId);
        const sectionId = table.dataset.sectionId;

        const customEvent = new CustomEvent('chosenOptionDetailsClick', {
            detail: {
                button,
                sectionId,
                questionId,
            },
        });
        window.dispatchEvent(customEvent);
    }
}
