import '@melloware/coloris/dist/coloris.css';

import Coloris from '@melloware/coloris';
import Swiper from 'swiper';
import * as THREE from 'three';

import FormSection from '../formSection';

/**
 * This class is responsible for implementing the functionality of form section A-1
 *
 * @class FormSectionA1
 * @extends { FormSection }
 */
export default class FormSectionA1 extends FormSection {
    /**
     * Container of the current section.
     * @param { HTMLElement } container - Main Container HTMLElement
     */
    constructor(container) {
        super(container);

        // experimental
        //this.initColorPickerWidget();
    }

    /**
     * Attach Event Listeners
     */
    initListeners() {
        if (this.inputs.length) {
            [...this.inputs].forEach((question) => {
                question.addEventListener(
                    'change',
                    this.questionChangeHandler.bind(this),
                );
            });
        }
        this.container.addEventListener(
            'click',
            this.containerClicksHandler.bind(this),
        );
        this.container.addEventListener(
            'sliderSlideToImage',
            this.slideToImageEventHandler.bind(this),
        );
    }

    /**
     * Fires when there is a click on a section container block
     * @param { Event } event - click event
     */
    containerClicksHandler(event) {
        const target = event.target;
        if (
            target.closest(`[data-role='section-option-details-btn']`) ||
            target.closest(`[data-role='section-question-details-btn']`)
        ) {
            this.showQuestionDetails(event);
        }
    }

    /**
     *
     * @param { Event } event
     */
    showQuestionDetails(event) {
        event.preventDefault();

        const target = event.target;
        const container = target.closest(`[data-question-id]`);
        if (!container) {
            return;
        }
        const questionId = parseInt(container.dataset.questionId);
        let optionId = 0;
        if (target.closest(`button[data-option-id]`)) {
            optionId = Number(
                target.closest(`button[data-option-id]`).dataset.optionId,
            );
        }

        this.getQuestionDetails(questionId, optionId);
    }

    /**
     *
     * @param { number } questionId
     * @param { number } clickedOptionId
     */
    getQuestionDetails(questionId, clickedOptionId) {
        const data = {
            action: FormSection.backendStaticData.action,
            _nonce: FormSection.backendStaticData.nonce,
            questionId: questionId,
            chosenOption: this.currentStateData[questionId],
            sectionId: this.currentSectionId,
        };

        const modalInner = document.querySelector(
            `[data-role='modal-inner-content']`,
        );
        const modalLoader = document.querySelector(
            `[data-role='modal-loader']`,
        );
        if (!modalInner || !modalLoader) {
            return;
        }

        $.ajax({
            type: 'POST',
            url: FormSection.backendStaticData.ajaxUrl,
            data: data,
            beforeSend: () => {
                modalInner.style.display = 'none';
                modalLoader.style.display = 'flex';

                /* eslint-disable no-undef */
                MicroModal.show('form-flow-option-details');
                /* eslint-enable no-undef */
            },
            success: (response) => {
                // HTML output of the backend response
                const output = response.data.output;
                if (!output) {
                    return;
                }

                // Put backend HTML response part within a modal window
                modalInner.innerHTML = output;
                // Show modal window
                modalInner.style.display = 'block';
                // Slider element within the backend response
                const modalSlider = modalInner.querySelector(
                    `[data-role='question-details-slider']`,
                );

                // If there is a slider in the response, we need to init it
                if (modalSlider) {
                    // Slide that is corresponds to the clicked option if options details button is clicked
                    let chosenOptionSlide = null;
                    // Index of this active slide. 0 by default
                    let chosenOptionSlideIndex = 0;
                    // Number of slides
                    const slides = modalInner.querySelectorAll('.swiper-slide');
                    // If the 'clickedOptionId' param is passed it means that options 'Details' button is clicked and we should set a proper slider initial slide index
                    if (clickedOptionId > 0) {
                        // Clicked option slide element
                        chosenOptionSlide = modalSlider.querySelector(
                            `.swiper-slide[data-option-id='${clickedOptionId}']`,
                        );
                        // Clicked option slide element index
                        chosenOptionSlideIndex = chosenOptionSlide
                            ? Number(chosenOptionSlide.dataset.slideIndex ?? 0)
                            : 0;
                    }
                    const slidesPerView = modalSlider.dataset.slides
                        ? Number(modalSlider.dataset.slides)
                        : 3;
                    new Swiper(modalInner.querySelector('.swiper-container'), {
                        loop: false,
                        speed: 600,
                        centerInsufficientSlides: true,
                        enabled: slides.length > slidesPerView,
                        autoplay: false,
                        slidesPerView: 1,
                        spaceBetween: 55,
                        pagination:
                            slides.length > slidesPerView
                                ? {
                                      el: modalInner.querySelector(
                                          '.swiper-pagination',
                                      ),
                                      type: 'fraction',
                                  }
                                : false,
                        initialSlide: chosenOptionSlideIndex,
                        navigation:
                            slides.length > slidesPerView
                                ? {
                                      nextEl: modalInner.querySelector(
                                          '.swiper-button-next',
                                      ),
                                      prevEl: modalInner.querySelector(
                                          '.swiper-button-prev',
                                      ),
                                  }
                                : false,

                        breakpoints: {
                            // when window width is >= 320px
                            1140: {
                                slidesPerView: slidesPerView,
                            },
                        },
                    });
                }

                // Removing loader animation
                modalLoader.style.display = 'none';
            },
            complete: (xhr) => {
                //console.log(this, 'complete');
            },
            error: (xhr) => {
                //console.log('error:', xhr);
            },
        });
    }

    /**
     * Section question change handler. Updates current state object, update Gallery/3D Model
     * @param { Event } event - Click event
     */
    questionChangeHandler(event) {
        const target = event.target;
        this.updateInputsLabel(target);

        if (!this.firstLoad) {
            this.updateStateDataByInputs();
            this.updateGallery();
            this.slideToImageEventTrigger(target);
            this.updateModel();
        }
    }

    /**
     * For certain form fields there is a need to show a particular slider slide.
     * For this purpose we are dispatching a new event in this function
     *
     * @param input
     */
    slideToImageEventTrigger(input) {
        const fieldsSlidesMap = this.getFieldsSlidesMap();
        if (Object.keys(fieldsSlidesMap).includes(input.name)) {
            const event = new CustomEvent('sliderSlideToImage', {
                detail: {
                    slideIndex: fieldsSlidesMap[input.name],
                },
            });
            this.container.dispatchEvent(event);
        }
    }

    /**
     * Fires when it is needed to show a particular swiper slide
     *
     * @param event
     */
    slideToImageEventHandler(event) {
        if (event.detail.slideIndex !== undefined) {
            this.getSwiperSlider().slideTo(parseInt(event.detail.slideIndex));
        }
    }

    /**
     * Change the chosen option label if needed
     * @param { HTMLElement } input
     */
    updateInputsLabel(input) {
        const container = input.closest(`[data-role='questions-group']`);
        if (!container || !input.getAttribute('data-title')) {
            return;
        }

        const label = container.querySelector(`[data-role='chosen-label']`);
        if (label) {
            label.innerText = input.dataset.title;
        }
    }

    /**
     * Updates 3D model while filters values are being changed
     */
    updateModel() {
        if (!this.scene) {
            return;
        }

        const stuccoNodes = this.getStuccoNodes();
        const panelNodes = this.getPanelNodes();
        const guttersNodes = this.getGuttersNodes();
        const sectionData = this.getSectionStateData();

        this.scene.traverse((node) => {
            if (node.isMesh) {
                // Stucco color
                if (stuccoNodes.includes(node.name)) {
                    const stuccoNodeColor = this.getNodeColor(
                        sectionData['170'],
                    );
                    if (stuccoNodeColor) {
                        node.material.color.set(
                            new THREE.Color(stuccoNodeColor),
                        );
                    }
                }

                // Panel Color
                if (panelNodes.includes(node.name)) {
                    const panelNodeColor = this.getNodeColor(
                        sectionData['167'],
                    );
                    if (panelNodeColor) {
                        node.material.color.set(
                            new THREE.Color(panelNodeColor),
                        );
                    }
                }

                if (guttersNodes.includes(node.name)) {
                    // node.visible = sectionData['176'] === '245';
                    // Make it visible by default
                    node.visible = true;
                }
            }
        });

        this.isModelInitialStateSet = true;
    }

    /**
     * Returns a list of stucco nodes names depending on the current floor plan
     * @return {*[]}
     */
    getStuccoNodes() {
        const floorPlanId = this.getFloorPlanId();
        let nodes = [];
        switch (floorPlanId) {
            // Studio Z
            case 281:
                nodes = ['Studio__-OBJ_5'];
                break;

            // Studio L
            case 280:
                nodes = ['Studio_L_updated_as_per_gutter_10'];
                break;

            // Studio C
            case 279:
                nodes = ['studio_c_OBJ_1001'];
                break;

            // Standard Z
            case 214:
                nodes = ['Z_STD_SKP_new_4001_3'];
                break;

            // Standard C
            case 212:
                nodes = ['STANDARD_C_MODEL-_OBJ_2003'];
                break;

            // Standard L
            case 271:
                nodes = ['L_STD_SKP_04-OBJ_3'];
                break;
        }

        return nodes;
    }

    /**
     * Returns a list of panel nodes names depending on the current floor plan
     * @return {*[]}
     */
    getPanelNodes() {
        const floorPlanId = this.getFloorPlanId();
        let nodes = [];
        switch (floorPlanId) {
            // Studio Z
            case 281:
                nodes = ['Studio__-OBJ_1'];
                break;

            // Studio L
            case 280:
                nodes = [];
                break;

            // Studio C
            case 279:
                nodes = [];
                break;

            // Standard Z
            case 214:
                nodes = ['Z_STD_SKP_new_4001_1'];
                break;

            // Standard C
            case 212:
                nodes = [];
                break;

            // Standard L
            case 271:
                nodes = ['L_STD_SKP_04-OBJ_1'];
                break;
        }

        return nodes;
    }

    /**
     * Returns a list of gutters nodes names depending on the current floor plan
     * @return {*[]}
     */
    getGuttersNodes() {
        const floorPlanId = this.getFloorPlanId();
        let nodes = [];
        switch (floorPlanId) {
            // Studio Z
            case 281:
                nodes = ['Studio_Z_with_gutter_obj'];
                break;

            // Studio L
            case 280:
                nodes = ['Studio_L_GUTTERS_updated'];
                break;

            // Studio C
            case 279:
                nodes = ['studio_c_gutter_1_obj', 'studio_c_gutter_2_obj'];
                break;

            // Standard Z
            case 214:
                nodes = ['Z_STD-GUTTER_OBJ'];
                break;

            // Standard L
            case 271:
                nodes = ['L_STD_SKP_gutters_updated'];
                break;

            // Standard C
            case 212:
                nodes = [
                    'STANDARD_C_MODEL-_GUTTER_2_OBJ',
                    'STANDARD_C_MODEL-_GUTTER_1_OBJ',
                ];
                break;
        }

        return nodes;
    }

    /**
     * Update slider gallery's images depending on the current state
     */
    updateGallery() {
        if (
            !FormSection.backendStaticData ||
            !FormSection.backendStaticData.sliderImages
        ) {
            return;
        }
        const sliderImages = FormSection.backendStaticData.sliderImages;
        for (const sectionId in sliderImages) {
            if (
                sectionId === this.currentSectionId &&
                this.sliderSwiper !== null
            ) {
                for (const slideIndex in sliderImages[sectionId]) {
                    const questionsRules =
                        sliderImages[sectionId][slideIndex].questions;
                    const variants =
                        sliderImages[sectionId][slideIndex].variants;
                    const path = sliderImages[sectionId][slideIndex].path;
                    if (!questionsRules || !variants || !path) {
                        continue;
                    }

                    const imageFileName = this.getCurrentSliderImage(
                        questionsRules,
                        variants,
                    );
                    if (!imageFileName) {
                        continue;
                    }
                    const image = `${this.getUploadsDirUrl()}/${path}/${imageFileName}`;

                    this.updateSliderImageByIndex(slideIndex, image);
                }
            }
        }
    }

    /**
     * Returns the uploads folder directory URL
     *
     * @return {string|*}
     */
    getUploadsDirUrl() {
        if (
            !FormSection.backendStaticData ||
            !FormSection.backendStaticData.uploadsFolderUrl
        ) {
            return `${window.location.origin}/wp-content/uploads`;
        }

        return FormSection.backendStaticData.uploadsFolderUrl;
    }

    /**
     *
     * @param questionsRules
     * @param variants
     * @return {string}
     */
    getCurrentSliderImage(questionsRules, variants) {
        const currentData = this.getSectionStateData();
        const questions = questionsRules.split(';');
        const filtersData = [];
        questions.forEach((question) => {
            filtersData.push(currentData[question] ?? '');
        });
        const filtersValues = filtersData.join(';');

        return variants[filtersValues] ?? '';
    }

    /**
     * Returns color's hex value for the passed option
     * @param optionId
     * @return {number}
     */
    getNodeColor(optionId) {
        let color = 0xeeeeee;

        switch (optionId) {
            case '234':
                // Light Grey
                color = 0x8a8581;
                break;
            case '236':
                // Dark Grey
                color = 0x625c5e;
                break;
            case '238':
                // Grey
                //color = 0x9e9f9a;
                color = 0x3d3d39;
                break;
            case '240':
                // White
                //color = 0xedece6;
                color = 0xffffff;
                break;
            case '242':
                // Green
                //color = 0x4e5147;
                //color = 0x060705;
                //color = 0x090a06;
                color = 0x0c0e08;
                //color = '#090a06';
                break;
            case '243':
                // Blue
                //color = 0x244653;
                //color = 0x333367;
                //color = 0x103E56;
                //color = 0x244653;
                //color = 0x163250;
                //color = 0x00265f;
                //color = 0x042141;
                //color = 0x0b151b;
                color = 0x020911;
                //color = 0x252549;
                break;
        }

        return color;
    }

    /**
     * Update a section's slider image by the slide's index and a new image URL
     * @param { number } index       - slide's index
     * @param { string } newImageUrl - updated image's URL
     */
    updateSliderImageByIndex(index, newImageUrl) {
        const slide = this.sliderSwiper.slides[index];
        if (slide) {
            const img = slide.querySelector('img');
            const picture = slide.querySelector('picture');

            this.sliderElement.classList.add('loading');
            this.loadImage([...img.classList], newImageUrl).then((image) => {
                picture.innerHTML = '';
                picture.append(image);
                this.sliderElement.classList.remove('loading');
            });
        }

        this.sliderSwiper.update();
    }

    /**
     * Loads an image and returns a Promise that resolves with the image element.
     * @param { string[] } classList - Array of CSS classes to add to the image element.
     * @param { string } url         - URL of the image to load.
     * @returns { Promise<HTMLImageElement> } A Promise that resolves with the loaded image element.
     */
    loadImage(classList, url) {
        return new Promise((resolve) => {
            const image = new Image();
            image.src = url;
            image.classList.add(...classList);
            image.addEventListener('load', () => {
                resolve(image);
            });
        });
    }

    /**
     * Updates the current state data object and dispatch a new custom event
     */
    updateStateDataByInputs() {
        this.currentStateData = this.collectStateData();
        const event = new CustomEvent('sectionDataUpdated', {
            detail: {
                section: this.currentSectionId,
                ignoreUrlUpdating: this.firstLoad,
                sectionStateData: this.currentStateData,
            },
        });
        window.dispatchEvent(event);

        this.firstLoad = false;
    }

    /**
     * Updates the current state object and trigger a related event on window
     * @param { object } data
     */
    updateStateDataByObject(data) {
        this.currentStateData = data;
        const event = new CustomEvent('sectionDataUpdated', {
            detail: {
                section: this.currentSectionId,
                ignoreUrlUpdating: this.firstLoad,
                sectionStateData: this.currentStateData,
            },
        });
        window.dispatchEvent(event);

        this.firstLoad = false;
    }

    /**
     * This method creates an object containing the answers to all the questions in the current section.
     * It iterates through each question in the section and adds the answer to the object, using the question as the key and the answer as the value.
     * The completed object is then returned.
     * @returns object
     */
    collectStateData() {
        let data = {};
        [...this.inputs].map((question) => {
            if (question.checked) {
                data[question.name] = data[question.name]
                    ? data[question.name]
                          .split(',')
                          .concat(question.value)
                          .join(',')
                    : question.value;
            }
        });

        return data;
    }

    /**
     * Updates the currentStateData object on page loading
     * @param { object } sectionStateObject
     * @param { object } formStateObject
     */
    setInitialState(sectionStateObject = {}, formStateObject = {}) {
        this.firstLoad = true;
        // If URL is 'clean' we need to set current state data object from default values
        if (Object.keys(sectionStateObject).length === 0) {
            this.updateStateDataByInputs();
            this.isSectionInitialStateSet = true;
            return;
        }

        for (const inputName in sectionStateObject) {
            const inputValues = sectionStateObject[inputName].split(',');
            inputValues.forEach((inputValue) => {
                // Radio and Checkbox Inputs
                const inputElement = this.container.querySelector(
                    `input[name='${inputName}'][value='${inputValue}']`,
                );
                if (inputElement) {
                    inputElement.checked = true;
                    inputElement.dispatchEvent(new Event('change'));
                }
            });
        }

        this.updateStateDataByObject(sectionStateObject);
        this.updateGallery();
        if (!this.isModelInitialStateSet && this.isModelLoaded) {
            this.updateModel();
        }
        this.isSectionInitialStateSet = true;
    }

    /**
     * The function returns an object that lists the names of form fields along with their corresponding slider slide indexes assigned to them.
     * key - field input name
     * value - slider slide index that should be shown on any field's changes
     */
    getFieldsSlidesMap() {
        return {
            // Master Bathroom Shower Floor
            195: 0,
            // Kitchen Appliances
            197: 1,
        };
    }

    /**
     * Initializes ColorPicker Widget for certain form questions
     */
    initColorPickerWidget() {
        if (!isDevelopmentMode()) {
            return;
        }
        if (!this.scene) {
            return;
        }
        const colorInputs = this.container.querySelectorAll(
            `[data-question-id='167'], [data-question-id='170']`,
        );
        if (colorInputs.length < 1) {
            return;
        }

        // Init color pickers
        document.addEventListener('modelLoaded', (event) => {
            // Panel Color and Stucco color questions
            //const colorQuestions = this.container.querySelectorAll(`[data-question-id='167'], [data-question-id='170']`);
            if (colorInputs.length < 1) {
                return;
            }
            Coloris.init();
            colorInputs.forEach((questionContainer) => {
                //console.log('this', this);
                addColorPicker(questionContainer, this);
            });
        });
        // Updating on inputs changes events
        colorInputs.forEach((question) => {
            question.addEventListener('change', (event) => {
                const questionId = event.target.name;
                const associatedColorInput = this.container.querySelector(
                    `input[name='custom_color_${questionId}']`,
                );
                if (associatedColorInput) {
                    //const colorValue = this.getNodeColor(event.target.value).toString(16);
                    associatedColorInput.value =
                        '#' +
                        this.getNodeColor(event.target.value)
                            .toString(16)
                            .padStart(6, '0');
                    associatedColorInput.dispatchEvent(
                        new Event('input', { bubbles: true }),
                    );
                }
            });
        });

        function addColorPicker(container, sectionClass) {
            const inputs = container.querySelectorAll(`input`);
            if (!inputs.length) {
                return;
            }

            const box = container.querySelector(
                '.m-form-flow-question-a-1__container',
            );
            if (!box) {
                return;
            }

            const questionId = container.dataset.questionId;
            const colorPickerContainer = box.cloneNode(true);
            const input = getColorPickerInputElement(questionId, sectionClass);
            colorPickerContainer.innerHTML = '';
            colorPickerContainer.appendChild(input);
            container.appendChild(colorPickerContainer);

            Coloris({
                el: '.coloris',
                theme: 'large',
                themeMode: 'light',
                format: 'hex',
                alpha: false,
                onChange: (color) => {
                    updateNodeColor(questionId, color, sectionClass);
                },
            });
        }

        function getCurrentColor(questionId, formSectionClass) {
            const sectionData = formSectionClass.getSectionStateData();
            if (!sectionData) {
                return '';
            }
            const questionValue = sectionData[questionId];
            if (!questionValue) {
                return '';
            }

            return (
                '#' +
                formSectionClass
                    .getNodeColor(questionValue)
                    .toString(16)
                    .padStart(6, '0')
            );
        }

        function updateNodeColor(questionId, color, formSectionClass) {
            let nodes = [];
            if (questionId === '167') {
                // Panel color
                nodes = formSectionClass.getPanelNodes();
            } else if (questionId === '170') {
                // Stucco Color
                nodes = formSectionClass.getStuccoNodes();
            }
            formSectionClass.scene.traverse((node) => {
                if (node.isMesh) {
                    // Stucco color
                    if (nodes.includes(node.name)) {
                        if (color) {
                            node.material.color.set(new THREE.Color(color));
                        }
                    }
                }
            });
        }

        function getColorPickerInputElement(questionId, formSectionClass) {
            const input = document.createElement('input');
            const currentColor = getCurrentColor(questionId, formSectionClass);
            input.setAttribute('type', 'text');
            input.setAttribute('class', 'coloris');
            input.setAttribute('name', `custom_color_${questionId}`);
            input.setAttribute('value', currentColor);

            return input;
        }

        function isDevelopmentMode() {
            return false;
        }
    }
}
