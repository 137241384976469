import MicroModal from 'micromodal';

export default () => {
    /**
     * Create variable with vimeo iframe
     */
    const iframe = $('#vimeo-video');

    /**
     * If there is an iframe, then create a vimeo instance
     */

    let player = '';
    if (iframe) {
        // eslint-disable-next-line no-undef
        player = new Vimeo.Player(iframe);
        /**
         * Setting (loop: true) is the best solution as it is
         * a feature of vimeo. Vimeo does not have a video restart
         * method, so you have to loop it.
         */
        // player.setLoop(true);
    }

    let modal = MicroModal.init({
        onShow: () => {
            $('body').addClass('modal-open');

            /**
             * When a modal window appears, start the video from the
             * beginning.
             */
            if (iframe) {
                player.setCurrentTime(0.01);
                player.play();
            }
        }, // [1]
        onClose: () => {
            $('body').removeClass('modal-open'); // [2]

            /**
             * Pause when hiding.
             */
            if (iframe) {
                player.pause();
            }
        },
        disableScroll: true, // [6]
        awaitOpenAnimation: false, // [8]
        awaitCloseAnimation: true, // [9]
    });
};
