import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

export default () => {
    Swiper.use([Autoplay, Navigation, Pagination]);

    // classes to animate slider container
    const beginningClasses = '!pr-20 sm:!pr-40 lg:!pr-0';
    const endClasses = '!pl-20 !pr-20 sm:!pl-40 lg:!pl-0';
    const middleClasses = '!pl-10 !pr-10 sm:!pl-20 sm:!pr-20 lg:!pl-0 lg:!pr-0';

    new Swiper('.js-o-slider-a-1', {
        loop: false,
        speed: 600,
        watchOverflow: true,
        // autoplay: {
        //     delay: 7000,
        // },
        slidesPerView: 1,
        spaceBetween: 20,
        breakpoints: {
            543: {
                slidesPerView: 2,
            },
        },
        navigation: {
            prevEl: '.js-o-slider-a-1-btn-prev',
            nextEl: '.js-o-slider-a-1-btn-next',
        },
        on: {
            afterInit: function (sw) {
                if (window.matchMedia('(min-width:1024px)').matches) {
                    sw.slideTo(sw.slides.length - 1);
                }
            },
            resize: function (sw) {
                const slider = sw.$el[0];
                if (window.matchMedia('(min-width:1024px)').matches) {
                    sw.slideTo(sw.slides.length - 1);
                    $(slider).removeClass(
                        beginningClasses +
                            ' ' +
                            middleClasses +
                            ' ' +
                            endClasses,
                    );
                } else {
                    $(slider).addClass(beginningClasses);
                    sw.slideTo(0);
                }
            },
            slideChange: function (sw) {
                animateSlider(sw);
            },
        },
    });

    function animateSlider(sw) {
        const slider = sw.$el[0];

        if (sw.isBeginning) {
            $(slider).removeClass(middleClasses + ' ' + endClasses);
            $(slider).addClass(beginningClasses);
        } else if (sw.isEnd) {
            $(slider).removeClass(middleClasses + ' ' + beginningClasses);
            $(slider).addClass(endClasses);
        } else {
            $(slider).removeClass(endClasses + ' ' + beginningClasses);
            $(slider).addClass(middleClasses);
        }
    }
};
