import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import MicroModal from 'micromodal';

import FormSectionA1 from '../../../../02-molecules/flow/form-section/form-section-a-1/formSectionA1';
import FormSectionA2 from '../../../../02-molecules/flow/form-section/form-section-a-2/formSectionA2';
import FormSection from '../../../../02-molecules/flow/form-section/formSection';

class FormFlow {
    /**
     * Component classes for blocks
     * @type { { container: string, navigation: string } }
     */
    static COMPONENT_CLASSES = {
        container: 'o-form-flow-a-1',
        navigation: 'm-form-flow-navigation',
    };

    /**
     * Main container of the bloc
     * @type { null | HTMLElement }
     */
    container = null;

    /**
     * Navigation Block
     * @type { null | HTMLElement }
     */
    navigation = null;

    /**
     * HTMLCollection of all the links within the navigation block
     * @type { null | HTMLCollection }
     */
    navigationLinks = null;

    /**
     * Form Element
     * @type { null |HTMLElement }
     */
    form = null;

    /**
     * HTML Collections of all the form sections
     * @type { null | HTMLCollection }
     */
    sectionsCollection = null;

    /**
     * An array of form sections
     * @type { object }
     */
    sections = {};

    /**
     * Object with the form data
     * @type { object }
     */
    currentStateData = {};

    /**
     * The name attribute value for an input that holds the current state data object
     * @type { string }
     */
    currentStateDataInputName = 'input_27';

    /**
     * Input HTMLElement that holds the current state data object
     * @type { null | HTMLElement }
     */
    currentStateInput = null;

    /**
     * Modal Gravity Form ID
     * @type {string}
     */
    modalFormId = 'modal-gf-form';

    modalFormSubmitRole = 'submit-btn';
    modalFormCustomSubmitRole = 'custom-submit-btn';
    talkToRepInputName = 'input_72';
    gFormId = 'gform_3';

    /**
     * Initializes a new instance of the class
     * @param { HTMLElement } container
     */
    constructor(container) {
        if (!container) {
            return;
        }

        this.container = container;
        this.initElements();
        this.initMicroModals();
        this.initListeners();
        this.initGsapEffects();
    }

    /**
     * Initialize a new GSAP scrollTrigger effect for the interior section block slider
     */
    initGsapEffects() {
        const mobileResolution = 1024;

        // Register ScrollTrigger plugin
        gsap.registerPlugin(ScrollTrigger);

        // ScrollTrigger instance
        let pinInstance = null;

        // Initialize a new gsap scrollTrigger effect
        function pinEffectInit() {
            if (window.innerWidth >= mobileResolution) {
                pinEffectEnable();
            } else {
                pinEffectDisable();
            }
        }

        // Enable the effect
        function pinEffectEnable() {
            if (pinInstance instanceof Object && pinInstance.enabled) {
                return;
            }
            // Choose the second form section
            const sectionBlock = document.querySelector(
                '.o-form-flow-section-a-1[data-type="1"]:nth-of-type(2)',
            );
            if (!sectionBlock) {
                return;
            }

            // Sticky Slider Element
            const sliderBlock = sectionBlock.querySelector(
                '.o-form-flow-section-a-1__sticky-inner',
            );
            if (!sliderBlock) {
                return;
            }
            sliderBlock.style.position = 'static';
            sliderBlock.style.top = '0';
            // Slider block height
            const sliderBlockHeight = sliderBlock.offsetHeight;

            // Select the right column as trigger element
            const rightColumn = sectionBlock.querySelector(
                '.o-form-flow-section-a-1__column--right',
            );
            const rightColumnHeight = rightColumn
                ? rightColumn.offsetHeight
                : 0;

            // Summary Section Block
            const summaryBlock = document.querySelector(
                '.o-form-flow-section-a-2.o-form-flow-a-1__form-flow-section-a-2',
            );
            const summaryBlockHeight = summaryBlock
                ? summaryBlock.offsetHeight
                : 0;

            // Sticky header block height
            const headerHeight = 120;
            // Gap between form sections
            const gap = 120;

            // Trigger start offset
            let triggerStart = headerHeight;
            // Trigger end offset
            let triggerEnd =
                rightColumnHeight +
                summaryBlockHeight +
                headerHeight -
                sliderBlockHeight -
                gap;

            // Set up the ScrollTrigger
            pinInstance = ScrollTrigger.create({
                trigger: rightColumn,
                start: `-=${triggerStart}px top`,
                end: `+=${triggerEnd}px`, // End the trigger when #second is at the top of the viewport
                pin: sliderBlock,
                pinSpacing: false, // Disable automatic spacing to prevent unwanted gaps
            });
        }

        // Disable Effect
        function pinEffectDisable() {
            if (pinInstance !== null) {
                pinInstance.disable();
                pinInstance.kill();
            }
        }

        // Init on page load
        pinEffectInit();

        // Re-init on resize
        window.addEventListener('resize', pinEffectInit);
    }

    /**
     * Initializing some needed properties
     */
    initElements() {
        this.navigation = this.container.querySelector(
            `[data-role='form-navigation']`,
        );
        this.navigationLinks = this.navigation
            ? this.navigation.querySelectorAll(`[data-role='section-anchor']`)
            : null;
        this.editLinks = this.container.querySelectorAll(
            `[data-role='edit-section-anchor']`,
        );
        this.form = this.container.querySelector(`[data-role='form']`);
        this.sectionsCollection = this.container.querySelectorAll(
            `[data-role='form-section']`,
        );
        [...this.sectionsCollection].map((section) => {
            let sectionItem = null;
            if (section.dataset.type === '1') {
                sectionItem = new FormSectionA1(section);
            } else if (section.dataset.type === '2') {
                sectionItem = new FormSectionA2(section);
            }
            if (sectionItem !== null) {
                const sectionId = sectionItem.getSectionId();
                this.sections[sectionId] = sectionItem;
            }
        });
        this.currentStateInput = this.container.querySelector(
            `input[name='${this.currentStateDataInputName}']`,
        );
        this.submitButton = this.container.querySelector(
            `[data-role='${this.modalFormSubmitRole}']`,
        );
        this.customSubmitButton = this.container.querySelector(
            `[data-role='${this.modalFormCustomSubmitRole}']`,
        );
        this.talkToRepInput = this.container.querySelector(
            `input[name='${this.talkToRepInputName}']`,
        );
        this.gform = this.container.querySelector(`form#${this.gFormId}`);
    }

    /**
     * Initializes the modal window
     */
    initMicroModals() {
        MicroModal.init({
            onShow: () => {
                this.setStateInputValue();
            },
            onClose: () => {},
            disableScroll: true,
            awaitOpenAnimation: false,
            //awaitCloseAnimation: true,
            openClass: 'is-open',
        });
    }

    /**
     * Assign input field value to current state object
     */
    setStateInputValue() {
        if (this.currentStateInput) {
            this.currentStateInput.value = JSON.stringify(
                this.currentStateData,
            );
        }
    }

    /**
     * Attach the needed actions to triggers/events
     */
    initListeners() {
        // HTMLDocument load event - starting point
        window.addEventListener('load', this.loadEventListener.bind(this));
        // Navigation Block Clicks - todo
        [...this.navigationLinks].map((link) => {
            link.addEventListener(
                'click',
                this.navigationClicksHandler.bind(this),
            );
        });
        // Edit Sections Links - todo
        [...this.editLinks].forEach((link) => {
            link.addEventListener('click', this.editLinksHandler.bind(this));
        });
        window.addEventListener('click', this.globalClicksHandler.bind(this));
        // Updating current state event
        window.addEventListener(
            'sectionDataUpdated',
            this.sectionStateUpdatedHandler.bind(this),
        );
        // Click options details button in the summary block
        window.addEventListener(
            'chosenOptionDetailsClick',
            this.chosenOptionDetailsClickHandler.bind(this),
        );
        // Re-render 3D models om resize event
        window.addEventListener('resize', () => {
            for (const sectionId in this.sections) {
                this.sections[sectionId].updateModelSizes();
            }
        });
        if (this.customSubmitButton) {
            this.customSubmitButton.addEventListener(
                'click',
                this.customSubmitButtonHandler.bind(this),
            );
        }
        window.addEventListener(
            'totalNetPriceUpdated',
            this.updateNavigationPrice.bind(this),
        );
        if (this.gform) {
            this.gform.addEventListener(
                'submit',
                this.gFormSubmitEventHandler.bind(this),
            );
        }
    }

    /**
     * Triggers when a user clicks a navigation link item
     * @param { Event } event - Click Event
     */
    navigationClicksHandler(event) {
        event.preventDefault();

        const target = event.target;

        this.highlightCurrentNavigationItem(target);
        const targetId = target.dataset.route || '';
        if (targetId) {
            this.scrollToSection(targetId);
        }
    }

    /**
     * Change the view of the current navigation item
     * @param { HTMLElement } target - element that was clicked
     */
    highlightCurrentNavigationItem(target) {
        // todo
    }

    /**
     * Scrolling to a section that has a data-id attribute value is equal to the passed one
     * @param { string } id - section data-id attribute value
     */
    scrollToSection(id) {
        [...this.sectionsCollection].map((section) => {
            if (section.getAttribute('data-id') === id) {
                section.scrollIntoView({
                    behavior: 'smooth',
                });
                return false;
            }
        });
    }

    /**
     * Updates page's URL according to the current state data
     * @param { CustomEvent } event - A Custom Event that is being dispatched each time state data is changed
     */
    sectionStateUpdatedHandler(event) {
        const sectionId = event.detail.section;
        this.currentStateData[sectionId] = this.getSectionStateData(sectionId);

        if (event.detail.ignoreUrlUpdating !== true) {
            this.updateURL();
        }
    }

    /**
     * Returns section's state data by its id
     * @param { string } sectionId
     * @return { object }
     */
    getSectionStateData(sectionId) {
        const section = this.sections[sectionId];
        if (!section) {
            return {};
        }

        return section.getSectionStateData();
    }

    /**
     * Updates page's URL according to the current state data
     */
    updateURL() {
        const stateURL = this.getCurrentStateDataAsURL();
        const newUrl = `${window.location.origin}${window.location.pathname}?${stateURL}`;

        history.pushState({}, '', newUrl);
    }

    /**
     * Returns a new URL that is a JSON representation of the current state
     * @return { string } - URL string that includes the current state data value
     */
    getCurrentStateDataAsURL() {
        const json = JSON.stringify(this.currentStateData);

        return encodeURIComponent(json);
    }

    /**
     * Runs on a window load event
     */
    loadEventListener() {
        this.checkModalForm();
        this.setStateFromURL();
    }

    /**
     * Opens a modal gf form if it has validation issues
     */
    checkModalForm() {
        if (this.modalFormHasErrors()) {
            MicroModal.show(this.modalFormId);
        }
    }

    /**
     * Returns true if the modal gf form has validation errors
     * @return {boolean}
     */
    modalFormHasErrors() {
        const modalFormErrorsBlock = this.container.querySelectorAll(
            `#${this.modalFormId} .gform_validation_errors, #${this.modalFormId} .validation_error`,
        );

        return modalFormErrorsBlock.length > 0;
    }

    /**
     * Decodes current URL of a page to an object and sets an initial state for every section
     */
    setStateFromURL() {
        const currentState = this.getCurrentStateDataFromURL();

        for (const sectionId in this.sections) {
            const sectionState =
                typeof currentState[sectionId] === 'object'
                    ? currentState[sectionId]
                    : {};
            this.sections[sectionId].setInitialState(
                sectionState,
                currentState,
            );
        }
    }

    /**
     * Decodes current URL of a page to an object
     * @return { object }
     */
    getCurrentStateDataFromURL() {
        const url = window.location.href;
        const searchParams = new URL(url).searchParams;
        let data = {};
        for (const [key, value] of searchParams) {
            try {
                data = {
                    ...data,
                    ...JSON.parse(decodeURIComponent(key)),
                };
            } catch (e) {
                console.log(`Invalid search parameters for key '${key}'`);
            }
        }

        return data;
    }

    editLinksHandler(event) {
        event.preventDefault();

        const target = event.target;
        const targetId = target.dataset.route || '';
        if (targetId) {
            this.scrollToSection(targetId);
        }
    }

    globalClicksHandler(event) {
        const target = event.target;
        if (
            !target.closest(`[data-role='summary-row-info']`) &&
            (target.closest(`[data-role='option-details-popup-close']`) ||
                target.closest(`[data-role='option-details-popup']`) === null)
        ) {
            this.closeOptionDetailsPopups();
        }
    }

    gFormSubmitEventHandler(event) {
        if (window.globalLoader) {
            window.globalLoader.showLoader(
                'Your quote is being processed. Please do not refresh your browser.',
            );
        }
    }

    customSubmitButtonHandler(event) {
        event.preventDefault();

        if (this.talkToRepInput) {
            this.talkToRepInput.value = '1';
            if (this.submitButton) {
                this.submitButton.click();
            }
        }
    }

    chosenOptionDetailsClickHandler(event) {
        this.closeOptionDetailsPopups();

        const { questionId, sectionId, button } = event.detail;
        const chosenOptionValue = this.getStateQuestionValue(
            sectionId,
            questionId,
        );
        const chosenOption = this.getOptionObject(chosenOptionValue);
        if (Object.keys(chosenOption).length < 1) {
            return;
        }
        const cell = button.closest('td');
        const popup = cell.querySelector(`[data-role='option-details-popup']`);
        const popupContent = cell.querySelector(
            `[data-role='option-details-popup-text']`,
        );

        popupContent.innerHTML = chosenOption.details;
        popup.classList.add('opened');
    }

    closeOptionDetailsPopups() {
        const popups = document.querySelectorAll(
            `[data-role='option-details-popup'].opened`,
        );
        popups.forEach((popup) => {
            popup.classList.remove('opened');
        });
    }

    getStateQuestionValue(sectionId, questionId) {
        if (!this.currentStateData[sectionId]) {
            return null;
        }

        const sectionData = this.currentStateData[sectionId];
        if (!sectionData[questionId]) {
            return null;
        }

        return Number(sectionData[questionId]);
    }

    getOptionObject(optionId) {
        const backendStaticData = FormSection.getBackendStaticData();
        if (
            typeof backendStaticData !== 'object' ||
            !Array.isArray(backendStaticData.options) ||
            backendStaticData.options.length < 1
        ) {
            return {};
        }

        const chosenOption = backendStaticData.options.filter(
            (option) => option.ID === optionId,
        );

        return chosenOption[0] ?? {};
    }

    /**
     * Updating navigation block total price value
     *
     * @param event
     */
    updateNavigationPrice(event) {
        const navigationTotalSumBlock = this.navigation.querySelector(
            `[data-role='navigation-total-price']`,
        );
        if (!navigationTotalSumBlock) {
            return;
        }
        const newPrice = event.detail.price
            ? parseFloat(event.detail.price)
            : 0;
        if (newPrice) {
            navigationTotalSumBlock.innerHTML = `Est: ${newPrice.toLocaleString(
                FormSectionA2.PRICE_LOCALE,
                FormSectionA2.PRICE_FORMAT,
            )}`;
        }
    }
}

function formFlowA1() {
    const formFlowContainer = document.querySelector(`[data-role='form-flow']`);
    if (formFlowContainer) {
        new FormFlow(formFlowContainer);
    }
}

export default formFlowA1;
