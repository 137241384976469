/*
 * hamburger
 *
 * runs the functionality of clicking the hamburger icon in the top nav
 */

const selector = '.js-hamburger';
const activeClass = 'is-active';
const bodyActiveClass = 'h-scroll-lock';
const headerActiveClass = 'o-header--hamburger-active';
const navMenu = '.js-slide-out';
const navMenuActiveClass = 'm-slide-out--active';
const headerSearchButton = 'o-header__search-button';
const searchBar = 'm-search-bar';
const mobileResolution = 1140;

export function hamburger() {
    $(selector).click((e) => {
        e.preventDefault();

        if ($(selector).hasClass(activeClass)) {
            $(selector).removeClass(activeClass);

            $('.js-body').removeClass(bodyActiveClass);
            $('.js-header').removeClass(headerActiveClass);

            // Makes slide-out appear
            $(navMenu).removeClass(navMenuActiveClass);
            $(navMenu).css('padding-top', '');
        } else {
            $(selector).addClass(activeClass);

            // Ensuring body cannot be scrolled up and down when the mobile nav is shown
            $('.js-body').addClass(bodyActiveClass);
            $('.js-header').addClass(headerActiveClass);

            $('.js-body').addClass('test');

            // Makes slide-out appear
            $(navMenu).addClass(navMenuActiveClass);
            $(navMenu).css('padding-top', getTopOffset());
        }

        $(`.${headerSearchButton}`).toggleClass(`${headerSearchButton}--anim`);

        if ($(`.${searchBar}`).hasClass(`${searchBar}--active`)) {
            $(selector).parent().siblings(headerSearchButton).trigger('click');
        }
    });

    $(window).on('resize', () => {
        if ($(window).width() >= mobileResolution) {
            if ($('.o-header-1__hamburger').hasClass('is-active')) {
                $('.o-header-1__hamburger').trigger('click');
            }
        } else {
            if ($(selector).hasClass(activeClass)) {
                $(navMenu).css('padding-top', getTopOffset());
            }
        }
    });

    function getTopOffset() {
        let offset = 0;
        if ($(window).width >= mobileResolution) {
            return offset;
        }
        const mainBar = $('.o-header-1__inner-wrapper');
        if (mainBar) {
            offset += mainBar.offset().top + mainBar.height();
        }

        return offset;
    }
}

export function hamburgerClose() {
    $(selector).removeClass(activeClass);
    $('.js-body').removeClass(bodyActiveClass);
    $('.js-header').removeClass(headerActiveClass);
    $(navMenu).removeClass(navMenuActiveClass);
}
