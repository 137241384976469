import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

/**
 * This class provides form flow implementation
 *
 * @class FormSection
 */
export default class FormSection {
    /**
     * Container of the form section
     * @type { null | HTMLElement }
     */
    container = null;

    /**
     * Slider Block HTML Element
     * @type { null | HTMLElement }
     */
    sliderElement = null;

    /**
     * Swiper Slider for the current section
     * @type { null | Swiper }
     */
    sliderSwiper = null;

    /**
     * HTML Element of a 3D model
     * @type { null | HTMLElement }
     */
    modelElement = null;

    /**
     * URL to 3D Model item
     * @type { string }
     */
    modelPath = '';

    /**
     *
     * @type {boolean}
     */
    isModelLoaded = false;

    /**
     *
     * @type {boolean}
     */
    isModelInitialStateSet = false;

    /**
     *
     * @type {boolean}
     */
    isSectionInitialStateSet = false;

    /**
     * HTMLCollection for the current section inputs/selects etc
     * @type { null | HTMLCollection }
     */
    inputs = null;

    /**
     * Data of the current form section
     * @type { object }
     */
    currentStateData = {};

    /**
     * Current Section data-id attribute value
     * @type { string }
     */
    currentSectionId = '';

    /**
     * This property indicates whether the process of loading the first page is currently active.
     * It is set to true when the process has begun and remains true until the process is complete
     * or until it is explicitly set to false
     * @type { boolean }
     */
    firstLoad = false;

    /**
     * 3D Model scene and camera aspect ratio
     */
    modelAspectRatio = 16 / 9;

    /**
     * 3D Model Container Block
     */
    modelContainer = null;

    /**
     * Three.js renderer instance
     */
    renderer = null;

    /**
     * Current Floor Plan WP_Post ID
     * @type {null}
     */
    postId = null;

    /**
     * This property holds an object containing all the relevant information for a question, including the options, associated images, and any rules that govern the question
     * @type { object }
     * @property { object } options       Options grouped by questions IDs
     * @property { object } sliderImages  Slider Images rules grouped by sections IDs
     */
    // todo
    /* eslint-disable no-undef */
    static backendStaticData =
        window.formFlowData !== undefined ? window.formFlowData : {};
    /* eslint-enable no-undef */

    /**
     * Initializes a new instance of the class
     * @param { HTMLElement } block
     */
    constructor(block) {
        this.container = block;

        this.initProperties();
        this.initMediaBlock();
        this.initListeners();
    }

    /**
     * Initializing some properties
     */
    initProperties() {
        this.sliderElement = this.container.querySelector(
            `[data-role='form-flow-slider']`,
        );
        this.modelElement = this.container.querySelector(
            `[data-role='form-flow-model']`,
        );
        if (this.modelElement && this.modelElement.dataset.modelUrl) {
            this.modelPath = this.modelElement.dataset.modelUrl;
        }
        this.inputs = this.container.querySelectorAll(
            `[data-role='form-flow-question']`,
        );
        this.currentSectionId = this.container.dataset.id;
        this.postId = this.getBackendPostId();
    }

    /**
     * Render the slider/model block
     */
    initMediaBlock() {
        if (this.modelPath) {
            this.initModel();
        } else if (this.sliderElement) {
            this.initSwiper();
        }
    }

    /**
     * Render a 3D model of a current section
     */
    initModel() {
        // Scene HTML container element
        const container = this.modelElement.querySelector(
            `[data-role='model-box']`,
        );
        if (!container) {
            return;
        }

        // The 'Model loading...' HTML element
        const loadingMessageElement = container.querySelector(
            `[data-role='loading-message']`,
        );

        // Creating new scene
        const scene = new THREE.Scene();
        // Adding a camera for a scene
        const camera = this.getSceneCamera();

        // Init Loader
        const loader = this.getSceneLoader();

        let textureLoader = new THREE.TextureLoader();
        let textures = [];
        let textureNames = [];

        const promises = textureNames.map((textureName) => {
            return new Promise((resolve, reject) => {
                textureLoader.load(
                    '/wp-content/uploads/2022/12/' + textureName,
                    (texture) => {
                        textures.push(texture);
                        resolve();
                    },
                    undefined,
                    reject,
                );
            });
        });

        // Loading a model
        loader.load(
            this.modelPath,
            (gltf) => {
                // Remove the loading message
                if (loadingMessageElement) {
                    loadingMessageElement.remove();
                }

                // Adding a model to the scene
                scene.add(gltf.scene);

                // Re-calculating scene and cameras' positions
                const box = new THREE.Box3().setFromObject(gltf.scene);
                const size = box.getSize(new THREE.Vector3()).length();
                const center = box.getCenter(new THREE.Vector3());
                scene.position.x += scene.position.x - center.x;
                scene.position.y += scene.position.y - center.y;
                scene.position.z += scene.position.z - center.z;
                // camera.near = size / 10;
                // camera.far = size * 10;
                // camera.position.copy(center);
                camera.position.z = 0;
                camera.position.y = -1;
                camera.position.x = 0;

                // assuming you have a Three.js camera and a new target vector
                // const newTarget = new THREE.Vector3(0, 0, 0); // example target position

                camera.lookAt(center);

                // Updating Matrix
                camera.updateProjectionMatrix();

                /**/
                const roofNames = [
                    // 'STANDARD_C_MODEL-_OBJ_2003',
                    // 'STANDARD_C_MODEL-_OBJ_2003_1',
                    // 'STANDARD_C_MODEL-_OBJ_2003_2',
                    // 'STANDARD_C_MODEL-_OBJ_2003_3',
                    // 'STANDARD_C_MODEL-_OBJ_2003_4',
                    // 'STANDARD_C_MODEL-_OBJ_2003_5',
                    // 'STANDARD_C_MODEL-_OBJ_2003_6',
                    // 'STANDARD_C_MODEL-_OBJ_2003_7',
                    // 'STANDARD_C_MODEL-_OBJ_2003_8',
                    'STANDARD_C_MODEL-_OBJ_2003_9',
                    //'STANDARD_C_MODEL-_OBJ_2003_10',
                    // 'STANDARD_C_MODEL-_OBJ_2003_11',
                    // 'STANDARD_C_MODEL-_OBJ_2003_12',
                ];
                scene.traverse((node) => {
                    if (roofNames.includes(node.name)) {
                        node.material.color.set(new THREE.Color(0x625c5e));
                    }
                });
                /**/

                // Applying textures
                for (let i = 0; i < textures.length; i++) {
                    gltf.scene.traverse((child) => {
                        if (child.isMesh) {
                            child.material.map = textures[i];
                            child.material.color.setHex(0x222222);
                        }
                    });
                }
                if (
                    !this.isModelInitialStateSet &&
                    this.isSectionInitialStateSet
                ) {
                    this.updateModel();
                }
                this.isModelLoaded = true;

                const customEvent = new CustomEvent('modelLoaded', {
                    detail: {
                        message: '',
                    },
                });
                // Dispatch the custom event
                document.dispatchEvent(customEvent);
            },
            undefined,
            function (error) {
                console.error(error);
            },
        );

        //This light globally illuminates all objects in the scene equally
        //let ambientLight = new THREE.AmbientLight(0x888888);
        let ambientLight = new THREE.AmbientLight(0xffffff, 0.7);
        scene.add(ambientLight);

        //A light that gets emitted in specific directions (X and Z axis)
        for (let i = 0; i < 4; i++) {
            // New Light Object
            let light = new THREE.DirectionalLight(0xffffff, 0.7);
            /**
             * Getting the next combinations
             *  - i = 0 - light position: (0, 0, 1), target position: (0, 0, -1);
             *  - i = 1 - light position: (1, 0, 0), target position: (-1, 0, 0);
             *  - i = 2 - light position: (0, 0, -1), target position: (0, 0, 1);
             *  - i = 3 - light position: (-1, 0, 0), target position: (1, 0, 0);
             */

            let x = (i % 2) * (i === 1 ? 1 : -1);
            let z = ((i + 1) % 2) * (i === 0 ? 1 : -1);

            light.position.set(x, 0, z);
            light.target.position.set(-1 * x, 0, -1 * z);

            // Add the light and the target to the scene
            scene.add(light);
            scene.add(light.target);
        }

        // Render instance
        const renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.shadowMap.enabled = true;
        renderer.shadowMap.type = THREE.PCFSoftShadowMap;

        // Set the size of the scene
        renderer.setSize(
            container.offsetWidth,
            container.offsetWidth / this.modelAspectRatio,
        );
        //You can change the resolution of the renderer by setting the pixelRatio property to a value higher than 1
        renderer.setPixelRatio(window.devicePixelRatio);

        // Set background color
        renderer.setClearColor(0xf3f4f1);
        renderer.localClippingEnabled = true;

        renderer.outputEncoding = THREE.sRGBEncoding;
        // Append a scene to its HTML container
        container.appendChild(renderer.domElement);

        // Adding camera controls
        const controls = new OrbitControls(camera, renderer.domElement);
        // controls.minDistance = 1100;
        controls.minDistance = 80;
        // controls.maxDistance = 1100;
        controls.maxDistance = 80;
        controls.maxPolarAngle = Math.PI / 2;

        // Rendering process
        function render() {
            controls.update();
            renderer.render(scene, camera);
            requestAnimationFrame(render);
        }
        renderer.render(scene, camera);

        this.scene = scene;
        this.modelContainer = container;
        this.renderer = renderer;

        requestAnimationFrame(render);
    }

    /**
     * Initializing the current section's swiper slider
     */
    initSwiper() {
        if (this.sliderElement) {
            Swiper.use([Autoplay, Pagination, Navigation]);

            const slider =
                this.sliderElement.querySelector('.swiper-container');

            this.sliderSwiper = new Swiper(slider, {
                loop: false,
                speed: 600,
                autoplay: false,
                slidesPerView: 1,
                spaceBetween: 15,
                // todo - navigation
                navigation: {
                    prevEl: slider.querySelector('.swiper-button-prev'),
                    nextEl: slider.querySelector('.swiper-button-next'),
                },
                pagination: {
                    el: slider.querySelector('.swiper-pagination'),
                    type: 'bullets',
                    clickable: true,
                },
            });
        }
    }

    /**
     * This method serves as a blueprint for all child classes extending the current class,
     * as it must be implemented by all subclasses.
     * It serves as a requirement for inheriting from the parent class,
     * similar to an abstract method in that it must be overridden by the child classes to provide specific functionality.
     */
    initListeners() {
        throw new Error('This method should be specified in a children class');
    }

    /**
     * Returns a Swiper instance of the current section slider
     * @returns { Swiper | null }
     */
    getSwiperSlider() {
        return this.sliderSwiper;
    }

    /**
     * Returns an object that includes all the questions answers for the current form section
     * @returns { object }
     */
    getSectionStateData() {
        return this.currentStateData;
    }

    /**
     * Returns section's ID
     * @returns { string }
     */
    getSectionId() {
        return this.currentSectionId;
    }

    /**
     * Returns a PerspectiveCamera instance for a scene
     * @return { PerspectiveCamera }
     */
    getSceneCamera() {
        const camera = new THREE.PerspectiveCamera(
            65,
            this.modelAspectRatio,
            0.1,
            1000,
        );

        camera.updateProjectionMatrix();

        return camera;
    }

    /**
     * Returns a GLTFLoader instance for a scene
     * @return { GLTFLoader }
     */
    getSceneLoader() {
        const loader = new GLTFLoader();
        const dracoLoader = new DRACOLoader();

        // This is a path for the draco decoded. Temporarily it is statically located in the wp-content folder.
        // Draco loader is needed for Samara models. By default we do not need it.
        dracoLoader.setDecoderPath('/wp-content/uploads/draco/');
        loader.setDRACOLoader(dracoLoader);

        return loader;
    }

    /**
     * Returns an array of lights for a scene
     * @return {*[]}
     */
    getSceneLights() {
        const lightPos = 20;
        const lightsPositions = [
            [lightPos, lightPos, lightPos],
            [lightPos, lightPos, -lightPos],
            [lightPos, -lightPos, -lightPos],
            [lightPos, -lightPos, lightPos],
            [-lightPos, lightPos, lightPos],
            [-lightPos, lightPos, -lightPos],
            [-lightPos, -lightPos, -lightPos],
            [-lightPos, -lightPos, lightPos],
        ];
        const lights = [];

        lightsPositions.forEach((positions) => {
            const light = new THREE.PointLight(0xffffff);
            light.position.set(...positions);
            lights.push(light);
        });

        return lights;
    }

    static getBackendStaticData() {
        return FormSection.backendStaticData;
    }

    /**
     * This method serves as a blueprint for all child classes extending the current class,
     * as it must be implemented by all subclasses.
     * It serves as a requirement for inheriting from the parent class,
     * similar to an abstract method in that it must be overridden by the child classes to provide specific functionality.
     */
    setInitialState() {
        throw new Error('This method should be specified in a children class');
    }

    /**
     * Re-renders 3D Model according to container's sizes
     */
    updateModelSizes() {
        if (!this.modelPath || !this.scene || !this.modelContainer) {
            return;
        }

        this.renderer.setSize(
            this.modelContainer.offsetWidth,
            this.modelContainer.offsetWidth / this.modelAspectRatio,
        );
    }

    /**
     * Returns a WP_Post ID value that is coming from backend
     * @return {null|*|null}
     */
    getBackendPostId() {
        if (
            FormSection.backendStaticData &&
            FormSection.backendStaticData.postId
        ) {
            return FormSection.backendStaticData.postId;
        }

        return null;
    }

    /**
     * Returns a current Floor Plan WP_Post ID value
     * @return {null}
     */
    getFloorPlanId() {
        return this.postId;
    }
}
