import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

export default () => {
    Swiper.use([Autoplay, Navigation, Pagination]);

    const bulletTailwind = [
        'relative',
        'group',
        'block',
        'w-5',
        'h-5',
        'rounded-full',
        'bg-brand-color-1',
        'border-transparent',
        'opacity-20',
        'cursor-pointer',
        'duration-500',
        'hover:opacity-50',
        'hover:scale-75',
    ];
    const bulletActiveTailwind = [
        'cursor-auto',
        '!opacity-100',
        '!scale-100',
        '!border-brand-color-1',
    ];

    new Swiper('.js-o-slider-d-1', {
        loop: false,
        speed: 700,
        watchOverflow: true,
        slideToClickedSlide: true,
        noSwipingSelector: 'a',
        autoHeight: true,
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 20,
        breakpoints: {
            639: {
                slidesPerView: 2,
                slidesPerGroup: 2,
                spaceBetween: 50,
            },
            1200: {
                slidesPerView: 3,
                slidesPerGroup: 3,
                spaceBetween: 120,
            },
        },
        pagination: {
            el: '.js-o-slider-d-1-pagination',
            type: 'bullets',
            clickable: true,
            bulletClass: bulletTailwind.join(' '),
            bulletActiveClass: bulletActiveTailwind.join(' '),
            renderBullet: function (index, className) {
                let innerSpanClass = [
                    'absolute',
                    '-top-1.5',
                    '-bottom-1.5',
                    '-left-1.5',
                    '-right-1.5',
                    'border',
                    'border-solid',
                    'border-inherit',
                    'rounded-full',
                    'duration-500',
                    'group-hover:border-brand-color-1',
                ].join(' ');
                return `<span class="${className}"><span class="${innerSpanClass}"></span></span>`;
            },
        },
    });
};
