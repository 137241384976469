function navMegaDropdown() {
    const dropdowns = document.querySelectorAll(
        '[data-module="navMegaDropdown"]',
    );

    dropdowns.forEach(function (dropdown) {
        let parentDropdown = dropdown.closest('.js-dropdown');

        parentDropdown.addEventListener('dropdownOpenedByClick', (e) => {
            setTimeout(function () {
                document.querySelector('body').classList.add('h-scroll-lock');
            }, 1);
        });

        parentDropdown.addEventListener('dropdownHidden', (e) => {
            if (
                !document
                    .querySelector('.js-hamburger')
                    .classList.contains('is-active')
            ) {
                document
                    .querySelector('body')
                    .classList.remove('h-scroll-lock');
            }
        });
    });

    // 'Switch to' buttons functionality
    const linksSpecial = document.querySelectorAll(
        '.m-nav-mega-dropdown__link',
    );

    linksSpecial.forEach((link) => {
        link.addEventListener('click', (event) => {
            const target = event.target.getAttribute('data-target');
            if (!target) {
                return;
            }
            const targetLink = document.querySelector(
                `a.m-nav__link[data-id='${target}']`,
            );
            if (targetLink) {
                event.preventDefault();
                targetLink.click();
                event.stopPropagation();
            }
        });
    });
}

export default navMegaDropdown;
