function mAccordionB() {
    const selector = {
        accordion: '.m-accordion-b',
        group: '.m-list-b__item',
        title: '.m-list-b__title',
        text: '.m-list-b__text',
    };
    const classList = {
        accordionInit: 'm-accordion-b--initialized',
        groupOpen: 'm-accordion-b__item--opened',
        groupClose: 'm-accordion-b__item--closed',
    };

    function processClick(e) {
        const title = e.currentTarget;
        const group = title.closest(selector.group);

        if (group.classList.contains(classList.groupOpen)) {
            groupClose(group);
        } else {
            groupOpen(group);
        }
    }

    function groupOpen(group) {
        const text = group.querySelector(selector.text);

        group.classList.remove(classList.groupClose);
        group.classList.add(classList.groupOpen);
        // eslint-disable-next-line jquery/no-slide
        $(text).slideDown('fast');

        return group;
    }

    function groupClose(group) {
        const text = group.querySelector(selector.text);

        group.classList.add(classList.groupClose);
        group.classList.remove(classList.groupOpen);
        // eslint-disable-next-line jquery/no-slide
        $(text).slideUp('fast');

        return group;
    }

    /**
     * Initialize the accordion
     **/
    (function () {
        const accordions = document.querySelectorAll(selector.accordion);
        if (typeof accordions !== 'undefined' && accordions.length === 0) {
            return;
        }

        accordions.forEach(function (accordion) {
            const groups = accordion.querySelectorAll(selector.group);
            if (typeof groups !== 'undefined' && groups.length === 0) {
                return;
            }

            groups.forEach(function (group, groupIndex) {
                const title = group.querySelector(selector.title);
                if (!group) {
                    return;
                }

                title.addEventListener('click', processClick);

                if (groupIndex === 0) {
                    groupOpen(group);
                } else {
                    groupClose(group);
                }
            });

            // set class to indicate accordion initialized
            accordion.classList.add(classList.accordionInit);
        });
    })();
}

export default mAccordionB;
