import './global/js/util/modernizr';
import './global/js/util/polyfills';
import './style.scss';
import './global/js/core/core';

import cursorEffects from './global/js/animations/cursor-effects';
import parallaxScroll from './global/js/animations/parallax-light';
/*
 * Animations
 */
// import scrollSkewer from './animations/scroll-skewer';
import letterEffects from './global/js/animations/splittext';
/*
 * Misc
 */
import dropdownInit from './global/js/misc/dropdown/dropdown-init';
import externalLinks from './global/js/misc/external-links/external-links';
import scrollToAnchor from './global/js/misc/scroll/scroll-to-anchor';
/*
 * Atoms
 */
import floatingCta from './patterns/01-atoms/buttons/button-floating-cta-a/button-floating-cta-a-1/button-floating-cta-a-1';
import globalLoader from './patterns/01-atoms/global-loader/globar-loader-a/global-loader-a';
import scrollDown from './patterns/01-atoms/links/scroll-down/scroll-down';
import scrollToTop from './patterns/01-atoms/misc/scroll-to-top-a/scroll-to-top-a-1/scroll-to-top-a-1';
/*
 * Molecules
 */
import showAccordion from './patterns/02-molecules/accordions/accordion-a/accordion-a-1';
import mAccordionB from './patterns/02-molecules/accordions/accordion-b/accordion-b-1';
import debugControls from './patterns/02-molecules/admin/debug-controls-a/debug-controls-a-1';
import multiselectDropdown from './patterns/02-molecules/dropdowns/terms-multiselect-a/terms-multiselect-a-1';
import galleryA from './patterns/02-molecules/galleries/gallery-a/gallery-a-1';
import mapBlock from './patterns/02-molecules/maps/map-a/map-a-1';
import modal from './patterns/02-molecules/modals/modal-a/modal-a-1';
import { hamburger } from './patterns/02-molecules/nav/hamburger-a/hamburger-a-1';
import navigateCategory from './patterns/02-molecules/nav/nav-category-a/nav-category-a-1';
import navMegaDropdown from './patterns/02-molecules/nav/nav-mega-dropdown-a/nav-mega-dropdown-a-1';
import navMob from './patterns/02-molecules/nav/nav-mobile-a/nav-mobile-a-1';
import searchBar from './patterns/02-molecules/search/search-bar-a/search-bar-a-1';
import { slideOut } from './patterns/02-molecules/slide-outs/slide-out-a/slide-out-a-1';
import sliderImages from './patterns/02-molecules/sliders/slider-images-a/slider-images-a-1';
import sliderTestimonials from './patterns/02-molecules/sliders/slider-testimonials-a/slider-testimonials-a-1';
import socialShare from './patterns/02-molecules/social/social-share-a/social-share-a-1';
import tabs from './patterns/02-molecules/tabs/tabs-a/tabs-a-1';
// Form Flow
import formFlowA1 from './patterns/03-organisms/flow/form-flow-a/form-flow-a-1/form-flow-a-1';
// import filterType1 from './patterns/03-organisms/repeatable-custom/filter-a/filter-a-1/filter-a-1';
import oSliderA1 from './patterns/03-organisms/repeatable-custom/slider-a/slider-a-1/slider-a-1';
import oSliderB1 from './patterns/03-organisms/repeatable-custom/slider-b/slider-b-1/slider-b-1';
import oSliderC1 from './patterns/03-organisms/repeatable-custom/slider-c/slider-c-1/slider-c-1';
import oSliderD1 from './patterns/03-organisms/repeatable-custom/slider-d/slider-d-1/slider-d-1';
/*
 * Organisms
 */
import headerInit from './patterns/03-organisms/single/header/header-init';
import hero1 from './patterns/03-organisms/single/hero/hero-1/hero-1';
import hero2 from './patterns/03-organisms/single/hero/hero-2/hero-2';

/*
 * Plop Entry (do not remove)
 */
/// /DO NOT ERASE OR PLOP IMPORTING WILL FAIL
/// /IMPORT FROM PLOP

/*
 * Module Caller
 *
 * functions below are only run if the component exists (verified through a "data-module" in the html)
 * in the ajax container or page. Barba.js instantiates the functions below on container reload as well.
 *
 * Place in alphabetical order
 */
const modules = {
    showAccordion,
    mAccordionB,
    sliderImages,
    sliderTestimonials,
    hero1,
    hero2,
    oSliderA1,
    oSliderB1,
    oSliderC1,
    oSliderD1,
    navigateCategory,
    navMegaDropdown,
    searchBar,
    scrollDown,
    scrollToTop,
    floatingCta,
    socialShare,
    hamburger,
    slideOut,
    modal,
    debugControls,
    tabs,
    mapBlock,
    galleryA,
    // filterType1,
    multiselectDropdown,
    formFlowA1,
    /// /DO NOT ERASE OR PLOP IMPORTING WILL FAIL
    /// /MODULES FROM PLOP
};

/*
 * Fire JavaScript functions by if the associated "data-module" exists on the page.
 */
const calledModulesNames = [];
modules.instantiate = function (elem) {
    const $this = $(elem);
    const module = $this.attr('data-module');

    if (module === undefined) {
        throw 'Module not defined (use data-module="")';
    } else if (module in modules) {
        if ($this.attr('data-initialized') === 'true') {
            return;
        }
        new modules[module](elem);
        $this.attr('data-initialized', true);
    } else {
        throw `Module '${module}' not found`;
    }
};

jQuery(document).ready(() => {
    scrollToAnchor($);
    navMob($);
    externalLinks($);

    headerInit();
    dropdownInit();

    // Executing it here in order to avoid duplications
    window.globalLoader = globalLoader();

    /*
     * Loops through component functions that should only run if the component exists in the ajax container or page.
     * The actual check is performed through "data-module";
     */
    $('[data-module]').each(function () {
        if ($.inArray($(this).data('module'), calledModulesNames) == -1) {
            modules.instantiate(this);
            calledModulesNames.push($(this).data('module'));
        }
    });

    /*
     * GSAP based animations. Hide if not used.
     */
    // cursorEffects($);
    // letterEffects($);
    // scrollSkewer($);
});

jQuery(window).scroll(() => {
    parallaxScroll($);
});
