import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

export default () => {
    Swiper.use([Autoplay, Navigation, Pagination]);

    new Swiper('.js-o-slider-c-1', {
        loop: false,
        speed: 700,
        watchOverflow: true,
        slideToClickedSlide: true,
        noSwipingSelector: 'a',
        slidesPerView: 1,
        spaceBetween: 10,
        breakpoints: {
            767: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
        },
        on: {
            slideChange: function (sw) {
                const slider = sw.$el[0];
                const beginningClasses = '!pr-16 lg:!pr-60';
                const endClasses = '!pl-16 lg:!pl-60';
                const middleClasses = '!pl-8 !pr-8 lg:!pl-30 lg:!pr-30';

                if (sw.isBeginning) {
                    $(slider).removeClass(middleClasses + ' ' + endClasses);
                    $(slider).addClass(beginningClasses);
                } else if (sw.isEnd) {
                    $(slider).removeClass(
                        middleClasses + ' ' + beginningClasses,
                    );
                    $(slider).addClass(endClasses);
                } else {
                    $(slider).removeClass(endClasses + ' ' + beginningClasses);
                    $(slider).addClass(middleClasses);
                }
            },
        },
    });
};
