import Helpers from '/src/global/js/helpers/helpers.js';
/*
 * Headroom
 *
 * Calling the Headroom.js object
 */
/* eslint-disable */
let Headroom = require('headroom.js');

function headerInit() {
    let header = document.querySelector('[data-header]');
    let params = Helpers.getJsonFromAttr(header.dataset.header);

    if (params.setHeaderHeight || params.sticky) {
        setHeaderHeightVar(header);
        window.addEventListener('resize', function () {
            setHeaderHeightVar(header);
        });
    }

    if (params.hideAfterScroll) {
        // Construct an instance of Headroom, passing the element
        let headroom = new Headroom(header, {
            offset: 40,
            tolerance: {
                up: 15,
                down: 25,
            },
            classes: {
                // when element is initialised
                initial: 'l-headroom',
                // when scrolling up
                pinned: 'l-headroom--pinned',
                // when scrolling down
                unpinned: 'l-headroom--unpinned',
                // when above offset
                top: 'l-headroom--top',
                // when below offset
                notTop: 'l-headroom--not-top',
                // when at bottom of scoll area
                bottom: 'l-headroom--bottom',
                // when not at bottom of scroll area
                notBottom: 'l-headroom--not-bottom',
            },
            onUnpin: function () {
                var transform_offset = '-100%';

                if (params.hidePartly) {
                    let headerPartToHide = header.querySelector(
                        '[data-header-part-to-hide]',
                    );

                    if (document.contains(headerPartToHide)) {
                        var headerPartToHideHeight =
                            headerPartToHide.offsetHeight;
                        if (headerPartToHideHeight > 0) {
                            var transform_offset =
                                '-' + headerPartToHideHeight + 'px';
                        }
                    }
                }

                header.style.transform = 'translateY(' + transform_offset + ')';
                document
                    .querySelector('body')
                    .classList.add('l-body--header-unpinned');
            },
            onPin: function () {
                header.style.transform = '';
                document
                    .querySelector('body')
                    .classList.remove('l-body--header-unpinned');
            },
        });

        // Initialise
        headroom.init();
    }
}

function setHeaderHeightVar(header) {
    let headerHeight = header.offsetHeight;
    let headerPartToHide = header.querySelector('[data-header-part-to-hide]');

    var headerPartToHideHeight = document.contains(headerPartToHide)
        ? headerPartToHide.offsetHeight
        : 0;

    let r = document.querySelector(':root');
    r.style.setProperty('--header-height', headerHeight + 'px');
    r.style.setProperty('--header-height-mobile', headerHeight + 'px');
    r.style.setProperty('--header-part-to-hide', headerPartToHideHeight + 'px');
}

export default headerInit;
